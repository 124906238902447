function _taggedTemplateLiteral(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _templateObject() {
    var data = _taggedTemplateLiteral([
        "\n    box-shadow: 0px 0px ",
        " rgba(0, 0, 0, 0.25);\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _taggedTemplateLiteral([
        "\n    box-shadow: 0px 0px ",
        " rgba(0, 0, 0, 0.15);\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _taggedTemplateLiteral([
        "\n    box-shadow: 0px 0px ",
        " rgba(0, 0, 0, 0.3);\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _taggedTemplateLiteral([
        "\n    box-shadow: 0px 0px ",
        " rgba(0, 0, 0, 0.15);\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
import { rem } from "polished";
import { css } from "styled-components";
export var boxShadowPopover = css(_templateObject(), rem(40));
export var boxShadowFloatingButton = css(_templateObject1(), rem(40));
export var boxShadowBorder = css(_templateObject2(), rem(5));
export var boxShadowAvatar = css(_templateObject3(), rem(12));
